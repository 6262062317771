import grapesjs from "grapesjs";
const stopPropagation = e => e.stopPropagation();
const fontsToAdd = [
  "Alegreya",
  "Allerta",
  "Amaranth",
  "Angkor",
  "Anonymous Pro",
  "Anton",
  "Arimo",
  "Artifika",
  "Arvo",
  "Battambang",
  "Barlow Condensed",
  "Bayon",
  "Bentham",
  "Bokor",
  "Brawler",
  "Buda",
  "Cabin",
  "Candal",
  "Cantarell",
  "Cardo",
  "Caudex",
  "Chenla",
  "Corben",
  "Crimson Text",
  "Crushed",
  "Cuprum",
  "Dangrek",
  "Didact Gothic",
  "Domine",
  "Droid Sans",
  "Droid Serif",
  "EB Garamond",
  "Expletus Sans",
  "Francois One",
  "Freehand",
  "GFS Didot",
  "GFS Neohellenic",
  "Hanuman",
  "Helvetica",
  "Inconsolata",
  "Impact",
  "Josefin Slab",
  "Judson",
  "Jura",
  "Kenia",
  "Khmer",
  "Koulen",
  "Kreon",
  "Lato",
  "Lekton",
  "Lora",
  "Mako",
  "Maven Pro",
  "Merriweather",
  "Metal",
  "Metrophobic",
  "Molengo",
  "Montserrat",
  "Moul",
  "Muli",
  "Neuton",
  "News Cycle",
  "Nobile",
  "Nova Slim",
  "Nova Square",
  "Nunito",
  "Old Standard TT",
  "Open Sans",
  "Open Sans Condensed",
  "Orbitron",
  "Oswald",
  "PT Sans",
  "PT Serif",
  "Pacifico",
  "Raleway",
  "Roboto",
  "Rokkitt",
  "Shanti",
  "Siemreap",
  "Slabo 27px",
  "Source Sans Pro",
  "Tahoma",
  "Tenor Sans",
  "Terminal Dosis Light",
  "Tinos",
  "Times New Roman",
  "Ubuntu"
];


export default grapesjs.plugins.add(
  "gjs-plugin-ckeditor",
  (editor, opts = {}) => {
    let c = opts;

    let defaults = {
      // CKEditor options
      options: {},

      // On which side of the element to position the toolbar
      // Available options: 'left|center|right'
      position: "left"
    };

    // Load defaults
    for (let name in defaults) {
      if (!(name in c)) c[name] = defaults[name];
    }

    if (!window.CKEDITOR) {
      throw new Error("CKEDITOR instance not found");
    }

    editor.setCustomRte({
      enable(el, rte) {
        // If already exists I'll just focus on it
        if (rte && rte.status !== "destroyed") {
          this.focus(el, rte);
          return rte;
        }

        el.contentEditable = true;

        // Seems like 'sharedspace' plugin doesn't work exactly as expected
        // so will help hiding other toolbars already created
        let rteToolbar = editor.RichTextEditor.getToolbarEl();
        [].forEach.call(rteToolbar.children, child => {
          child.style.display = "none";
        });

        // Check for the mandatory options
        var opt = c.options;
        var plgName = "sharedspace";

        if (opt.extraPlugins) {
          if (typeof opt.extraPlugins === "string")
            opt.extraPlugins += "," + plgName;
          else opt.extraPlugins.push(plgName);
        } else {
          opt.extraPlugins = plgName;
        }

        if (!c.options.sharedSpaces) {
          c.options.sharedSpaces = { top: rteToolbar };
        }
        //set CKeditor toolbars and hide excess
        c.options.toolbarGroups = [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "forms", groups: ["forms"] },
          { name: "basicstyles", groups: ["basicstyles"] },
          { name: "links", groups: ["links"] },
          {
            name: "paragraph",
            groups: ["align", "indent", "bidi", "paragraph"]
          },
          "/",
          { name: "styles", groups: ["styles"] },
          { name: "colors", groups: ["colors"] },
          { name: "lists", groups: ["list"] }
        ];
        c.options.removeButtons =
          "BidiLtr,BidiRtl,Language,CreateDiv,Outdent,Indent,CopyFormatting,RemoveFormat,Anchor,Image,Flash,Table,Templates,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks,Maximize,About,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Scayt,SelectAll,Find,Replace,Redo,Undo,PasteText,PasteFromWord,Save,NewPage,Preview,Print,Source,Format,Styles,Subscript,Superscript";

        //add google fonts
        let googleFonts = fontsToAdd;
        c.options.removePlugins = "scayt,copyformatting";
        c.options.font_names = "";
        let importFonts = "https://fonts.googleapis.com/css?family=";
        for (var i = 0; i < googleFonts.length; i++) {
          c.options.font_names = c.options.font_names + ";" + googleFonts[i];
          importFonts += `${googleFonts[i].replace(" ", "+")}|`;
        }
        c.options.contentsCss = importFonts;
        c.options.skin = "custom-skin";
        // Init CkEditor
        rte = window.CKEDITOR.inline(el, c.options);
        //remove extra line spacing on enter

        window.CKEDITOR.addCss(".cke_editable p { margin: 0 !important; }");
        window.CKEDITOR.config.removePlugins = "magicline,showborders";
        // Make click event propogate
        rte.on("contentDom", () => {
          var editable = rte.editable();
          editable.attachListener(editable, "click", () => {
            el.click();
          });
        });

        // The toolbar is not immediatly loaded so will be wrong positioned.
        // With this trick we trigger an event which updates the toolbar position
        rte.on("instanceReady", e => {
          var toolbar = rteToolbar.querySelector("#cke_" + rte.name);
          if (toolbar) {
            toolbar.style.display = "block";
          }
          editor.trigger("canvasScroll");
        });

        // Prevent blur when some of CKEditor's element is clicked
        rte.on("dialogShow", e => {
          const editorEls = grapesjs.$(
            ".cke_dialog_background_cover, .cke_dialog"
          );
          ["off", "on"].forEach(m =>
            editorEls[m]("mousedown", stopPropagation)
          );
        });

        this.focus(el, rte);

        return rte;
      },

      disable(el, rte) {
        el.contentEditable = false;
        if (rte && rte.focusManager) rte.focusManager.blur(true);
      },

      focus(el, rte) {
        // Do nothing if already focused
        if (rte && rte.focusManager.hasFocus) {
          return;
        }
        el.contentEditable = true;
        rte && rte.focus();
      }
    });

    // Update RTE toolbar position
    editor.on("rteToolbarPosUpdate", pos => {
      // Update by position
      // eslint-disable-next-line default-case
      switch (c.position) {
        case "center":
          let diff = pos.elementWidth / 2 - pos.targetWidth / 2;
          pos.left = pos.elementLeft + diff;
          break;
        case "right":
          let width = pos.targetWidth;
          pos.left = pos.elementLeft + pos.elementWidth - width;
          break;
      }

      if (pos.top <= pos.canvasTop) {
        pos.top = pos.elementTop + pos.elementHeight;
      }

      // Check if not outside of the canvas
      if (pos.left < pos.canvasLeft) {
        pos.left = pos.canvasLeft;
      }
    });
  }
);

const allFonts = () => {
  let values = "";
  fontsToAdd.forEach(fontName => {
    values += `${fontName.replace(/ /g, "+")}|`;
  });
  return `https://fonts.googleapis.com/css?family=${values}&display=swap`;
};


const addFonts = editor => {
  let styleManager = editor.StyleManager;
  let fontProperty = styleManager.getProperty("typography", "font-family");
  let list = fontProperty.get("list");
  fontsToAdd.forEach(fontName => {
    list.push({ value: fontName, name: fontName });
  });
  list.sort((a, b) => (a.name > b.name ? 1 : -1));
  fontProperty.set("list", list);
  styleManager.render();
};

export { addFonts, allFonts };
