import "grapesjs/dist/css/grapes.min.css";
import grapesCustomCode from "grapesjs-custom-code";
import grapesCustomEditor, { addFonts } from "./grapes/textEditor";
import gjsForms from "grapesjs-plugin-forms";
import { StripePlugin } from "./grapes/plugins"; //"grapesjs-plugin-ckeditor";
import { primary, secondary } from "../../uitheme";
import { allFonts } from "./grapes/textEditor";

const InitEditor = async ({ editor, state, imageUpload, removeAsset }) => {
  //reset loading/saving URL on page change
  //load in blocks with new state info
  let { id } = state;
  const urlStore = `/api/editor/syncTemp?id=${id}`;
  //lazy load the editor
  return import("grapesjs").then(grapesjs => {
    //loading in Font awesome v4
    var FAv4 = document.createElement("link");
    FAv4.href =
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css";
    FAv4.rel = "stylesheet";
    document.getElementsByTagName("head")[0].appendChild(FAv4);
    editor = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: "#gjs",
      // Get the content for the canvas directly from the element
      fromElement: true,
      // Size of the editor
      height: "100vh",
      width: "100%",
      canvasCss: `p { margin: 0;} .gjs-comp-selected {outline: 3px solid ${secondary} !important} #wrapper {padding:0px !important} #gjs-badge {background-color:  ${secondary};}`,
      canvas: {
        // scripts: ["https://js.stripe.com/v3/"],
        styles: [allFonts()]
      },
      plugins: [
        StripePlugin,
        editor =>
          grapesCustomEditor(editor, {
            options: { sharedSpaces: false },
            position: "left"
          }),
        editor =>
          grapesCustomCode(editor, {
            placeholderContent: "Insert Your Custom Code",
            blockCustomCode: false
          }),
        editor =>
          gjsForms(editor, {
            blocks: []
          })
      ],

      storageManager: {
        id: "gjs-",
        type: "remote",
        autosave: false,
        urlStore: urlStore,
        urlLoad: urlStore,
        // For custom parameters/headers on requests
        // params: { _some_token: "...." },
        headers: { "x-auth-token": localStorage.token }
      },
      blockManager: {
        appendTo: ".blocks-container"
      },
      layerManager: {
        appendTo: ".layers-container"
      },
      // We define a default panel as a sidebar to contain layers
      panels: {
        defaults: [
          {
            id: "layers",
            el: ".panel__right",
            // Make the panel resizable
            resizable: {
              maxDim: 350,
              minDim: 200,
              tc: 0, // Top handler
              cl: 1, // Left handler
              cr: 0, // Right handler
              bc: 0, // Bottom handler
              // Being a flex child we need to change `flex-basis` property
              // instead of the `width` (default)
              keyWidth: "flex-basis"
            }
          },
          {
            id: "panel-switcher",
            el: ".panel__switcher",
            buttons: [
              {
                id: "show-blocks",
                active: true,
                className: "fa fa-th-large",
                command: "show-blocks",
                togglable: false
              },

              {
                id: "show-layers",
                active: true,
                className: "fa fa-bars",
                command: "show-layers",
                // Once activated disable the possibility to turn it off
                togglable: false
              },
              {
                id: "show-styles",
                active: true,
                className: "fa fa-paint-brush",
                command: "show-styles",
                togglable: false
              },
              {
                id: "show-settings",
                className: "fa fa-cog",
                command: "show-settings",
                togglable: false,
                attributes: { title: "Settings" }
              }
            ]
          }
        ]
      },
      selectorManager: {
        appendTo: ".styles-container"
      },
      styleManager: {
        appendTo: ".styles-container",
        sectors: [
          {
            name: "General",
            open: false,
            buildProps: [
              "display",
              "position",
              "top",
              "right",
              "left",
              "bottom"
            ]
          },
          {
            name: "Layout",
            open: false,
            buildProps: [
              "width",
              "height",
              "max-width",
              "min-height",
              "margin",
              "padding"
            ]
          },
          {
            name: "Typography",
            open: false,
            buildProps: [
              "font-family",
              "font-size",
              "font-weight",
              "letter-spacing",
              "color",
              "line-height",
              "text-align",
              "text-shadow"
            ],
            properties: [
              {
                property: "text-align",
                list: [
                  { value: "left", className: "fa fa-align-left" },
                  { value: "center", className: "fa fa-align-center" },
                  { value: "right", className: "fa fa-align-right" },
                  { value: "justify", className: "fa fa-align-justify" }
                ]
              }
            ]
          },
          {
            name: "Decorations",
            open: false,
            buildProps: [
              "border-radius-c",
              "background-color",
              "background",
              "border-radius",
              "border",
              "box-shadow"
            ]
          },
          {
            name: "Extra",
            open: false,
            buildProps: ["z-index", "transition", "perspective", "transform"],
            properties: [
              {
                type: "integer",
                name: "Z-Index", // Label for the property
                property: "z-index", // CSS property (if buildProps contains it will be extended)
                defaults: "auto" // Default value
              }
            ]
          }
        ]
      },
      traitManager: {
        appendTo: ".settings-container"
      },
      assetManager: {
        assets: [],
        noAssets: "Nothing Here Yet...",
        multiUpload: false,
        uploadFile: e => {
          const image = e.dataTransfer
            ? e.dataTransfer.files[0]
            : e.target.files[0];
          imageUpload(image);
        }
      },
      domComponents: {
        wrapper: {
          removable: false,
          copyable: false,
          draggable: false,
          components: [],
          traits: [],
          stylable: [
            "font-family",
            "font-size",
            "font-weight",
            "letter-spacing",
            "color",
            "line-height",
            "text-align",
            "text-shadow",
            "background",
            "background-color",
            "background-image",
            "background-repeat",
            "background-attachment",
            "background-position",
            "background-size"
          ]
        }
      }
    });
    //load fonts in
    editor.on("load", () => addFonts(editor));

    //When image is removed...
    editor.AssetManager.addType("image", {
      view: {
        onRemove(e) {
          e.stopPropagation();
          const { model } = this;
          if (window.confirm("Are you sure you want to remove this image?")) {
            removeAsset(model.id);
            model.collection.remove(model);
          }
        }
      }
    });

    // Open block manager
    var openBlockBtn = editor.Panels.getButton("panel-switcher", "show-blocks");
    openBlockBtn && openBlockBtn.set("active", 1);
    return editor;
  });
};

export default InitEditor;
