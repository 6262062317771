// export const primary = "#8BB3DC";
// export const primaryLight = "#D3E4F5";
// export const primaryDark = "#5185BB";
// export const secondary = "#83D9D0";
// export const secondaryDark = "#46B6AA";
// export const tertiary = "#939CE0";
// export const tertiaryDark = "#5B67C3";
// export const lightcolor = "#c2fbef";
// export const danger = "#FF7A62";
// export const dangerHover = "#FF3E1A";
export const primary = "#277cff";
export const primaryLight = "#92B1FF";
export const primaryDark = "#5686fb";
export const secondary = "#ffa41d";
export const secondaryDark = "#EF8F00";
export const tertiary = "#b3c7d6";
export const tertiaryDark = "#a0b4c7";
export const lightcolor = "#c2fbef";
export const danger = "#FF7A62";
export const dangerHover = "#FF3E1A";

export const myTheme = theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: "8px",
    backgroundColor: secondary
  },
  root: {
    flexGrow: 1
  },
  dangerButton: {
    border: "0px",
    margin: 2,
    borderRadius: ".4rem",
    textTransform: "none",
    fontWeight: "normal",
    backgroundColor: danger,
    "&:hover": {
      border: "0px",
      backgroundColor: dangerHover
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white"
  },
  xbutton: {
    borderRadius: "20px",
    margin: 0,
    padding: 0,
    minWidth: 20,
    backgroundColor: danger,
    "&:hover": {
      backgroundColor: dangerHover
    }
  },
  button: {
    border: "0px",
    margin: 2,
    borderRadius: ".4rem",
    textTransform: "none",
    fontWeight: "normal",
    backgroundColor: primary,
    "&:hover": {
      border: "0px",
      // color: secondary,
      backgroundColor: primaryDark
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white"
  },
  outlineButton: {
    borderRadius: ".4rem",
    textTransform: "none",
    letterSpacing: "2px",
    margin: 2,

    fontWeight: "bold",
    color: "white",
    backgroundColor: secondary,
    "&:hover": {
      backgroundColor: secondaryDark
    }
  },
  table: {
    width: "100%",
    tableLayout: "auto"
  },
  ctaButton: {
    border: "0px",
    margin: 2,
    padding: theme.spacing(2, 4, 2, 4),
    borderRadius: ".4rem",
    textTransform: "none",
    textAlign: "left",
    letterSpacing: "2px",
    fontWeight: "bold",
    backgroundColor: secondary,
    "&:hover": {
      border: "0px",
      backgroundColor: secondaryDark
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white"
  },
  ctaButtonTwo: {
    border: "0px",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 4, 2, 4),
    borderRadius: ".4rem",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: "bold",
    backgroundColor: secondary,
    "&:hover": {
      border: "0px",
      backgroundColor: secondaryDark
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white",
    "@media (max-width: 599px)": {
      padding: theme.spacing(2),
    }
  },
  ctaButtonThree: {
    border: "0px",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 4, 2, 4),
    borderRadius: ".4rem",
    textTransform: "none",
    letterSpacing: "2px",
    fontWeight: "bold",
    backgroundColor: primary,
    "&:hover": {
      border: "0px",
      backgroundColor: primaryDark
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white",
    "@media (max-width: 599px)": {
      padding: theme.spacing(2),
    }
  },
  smallButton: {
    minWidth: 0,
    padding: 5,
    margin: 2,
    border: "0px",
    textTransform: "none",
    fontWeight: "normal",
    backgroundColor: primary,
    "&:hover": {
      border: "0px",
      backgroundColor: primaryDark,
      color: "#fff"
    },
    "&:disabled": {
      border: "0px",
      color: "white",
      backgroundColor: "#919EAA"
    },
    color: "white"
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#9e9e9e"
  },
  closeButtonPayment: {
    position: "absolute",
    right: 0,
    top: 42,
    color: "#9e9e9e"
  },
  tab: {
    textTransform: "none",
    minHeight: "15px",
    fontSize: "14px",
    color: "black",
    opacity: 1,
    padding: 3
  },
  selTab: {
    textTransform: "none",
    minHeight: "15px",
    fontSize: "14px",
    color: "white",
    backgroundColor: primary,
    borderRadius: 5,
    padding: 3
  },
  sidebar: {
    flexGrow: 1,
    display: "flex",
    height: "100%"
  },
  indicator: {
    visibility: "hidden"
  },
  formControl: {
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  cell: {
    border: "1px solid grey",
    padding: 5
  },
  p: {
    color: "#fff"
  },
  "@global": {
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyle: "none"
    }
  },
  // utility classes

  sections: {
    borderRadius: ".4rem",
    padding: theme.spacing(6, 4, 6, 4),
    marginBottom: "10vh",
    textAlign: "left",
    "@media (max-width: 599px)": {
      padding: theme.spacing(3, 2, 3, 2)
    }
  },
  sectionHeader: {
    color: "textPrimary",
    lineHeight: "1.6",
    padding: theme.spacing(0, 4, 4, 0),
    "@media (max-width: 599px)": {
      padding: theme.spacing(2, 0, 4, 0)
    }
  },
  sectionSubheader: {
    lineHeight: 1.8,
    padding: theme.spacing(0, 4, 4, 0),
    "@media (max-width: 599px)": {
      paddingRight: theme.spacing(0)
    }
  },
  subsection: {
    backgroundColor: primary,
    color: "#fff",
    marginTop: theme.spacing(4),
    padding: theme.spacing(3, 2, 3, 2),
    borderRadius: ".5rem",
    "@media (max-width: 599px)": {
      margin: theme.spacing(1)
    }
  },
  subsectionHeader: {
    padding: theme.spacing(2, 0, 2, 0)
  },
  subsectionImage: {
    maxHeight: "50px",
    maxWidth: "50px"
  },
  card: {
    maxWidth: "100%",
    backgroundColor: "#f4f4f4",
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    "@media (max-width: 599px)": {
      marginRight: theme.spacing(0)
    }
  },
  cardHeader: {
    height: 100,
    fontSize: "4rem",
    padding: theme.spacing(4, 0, 4, 0)
  },
  imageContent: {
    padding: theme.spacing(2, 3, 2, 3),
    maxWidth: "615px",
    margin: "0 auto",
    "@media (max-width: 599px)": {
      padding: theme.spacing(2)
    }
  },
  imageContentAlt: {
    padding: theme.spacing(15, 3, 2, 3),
    // position: "relative",
    // top: "50%",
    // transform: "translateY(-50%)",
    "@media (max-width: 959px)": {
      direction: "column-reverse"
    },
    "@media (max-width: 599px)": {
      minHeight: "300px",
      padding: theme.spacing(2)
    }
  },
  verticallyCenter: {
    // position: "absolute"
  },

  // end utility classes
  wrapper: {
    backgroundColor: "#fff"
  },
  toolbar: {
    maxHeight: 48,
    flexWrap: "wrap",
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    alignItems: "center",
    margin: theme.spacing(1, 4)
  },
  heroContent: {
    textAlign: "left",
    backgroundColor: "#fff",
    paddingTop: theme.spacing(6),
    "@media (max-width: 960px)": {
      textAlign: "center",
      paddingTop: theme.spacing(3),
    }
  },
  pricingContent: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    paddingTop: theme.spacing(10),
    "@media (max-width: 960px)": {
      textAlign: "center"
    }
  },
  heroGrid: {
    paddingTop: "10vh",
    paddingBottom: "10vh",
    "@media (max-width: 599px)": {
      paddingTop: "5vh",
      paddingBottom: "5vh"
    }
  },
  heroText: {
    color: "textPrimary",
    lineHeight: "1.5",
    paddingBottom: theme.spacing(3)
  },
  heroPText: {
    color: "textSecondary",
    lineHeight: "1.4",
    paddingBottom: theme.spacing(3)
  },
  heroButton: {
    margin: theme.spacing(5, 5)
  },

  sectionOne: {
    backgroundColor: primary
  },
  sectionTwo: {
    // backgroundColor: tertiary,
    // height: "100%",
    // position: "relative"
  },
  sectionThree: {
    backgroundColor: primary
  },

  subsectionTwo: {
    color: "#000"
  },
  subsectionThree: {
    backgroundColor: primaryDark
  },
  cta: {
    textAlign: "left",
    backgroundColor: "#fff",
    "@media (max-width: 960px)": {
      textAlign: "center"
    }
  },
  footer: {
    // borderTop: `1px solid ${theme.palette.divider}`,
    maxWidth: "6000px",
    marginTop: "10vh",
    padding: theme.spacing(3, 4, 3, 4),
    color: "#fff",
    backgroundColor: primary,
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    top: 0
  },
  navButton: {
    color: "white",
    margin: 0,
    borderRadius: 0,
    height: "100%",
    boxShadow: "none",
    borderTop: "none",
    borderBottom: "none"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
});

//Styles must be applied ON the page they are used.. If they are created before, they are overwritten by other CSS
//...so don't use the below code :(
// export const useStyles = makeStyles(myTheme);

// export default () => {
//   return makeStyles(myTheme);
// };
