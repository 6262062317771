/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab
} from "@material-ui/core";
import MyTextField from "../common/MyTextField";
import MySelect from "../common/MySelect";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import clsx from "clsx";
import Alert from "react-s-alert";
import { getIntegration } from "../../actions/auth";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PublishPage(props) {
  const {
    classes,
    loading,
    pageInfo,
    user,
    editPage,
    getIntegration,
    editor: { data }
  } = props;
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [values, setValues] = React.useState({
    title: pageInfo.title || "",
    path: pageInfo.path || "",
    stack: pageInfo.stack || "",
    meta: {
      title: "",
      description: "",
      keywords: "",
      iconLink:""
    },
    autoresponder: pageInfo.autoresponder || "",
    autoresponderTags: pageInfo.autoresponderTags || "!optin1, !newLead",
    autoresponderList: pageInfo.autoresponderList || ""
  });

  const [lists, setLists] = React.useState([]);
  useEffect(() => {
    if (data.lists && data.lists.length) setLists(data.lists);
  }, [data.lists]);

  useEffect(() => {
    setValues({...pageInfo });
    if (pageInfo.live) {
      handleClose();
      Alert.success("Your page is published and may take a few minutes to appear globally!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo]);

  const [errors, setErrors] = React.useState({});
  useEffect(() => {
    if (Object.keys(props.errors).length > 0) setErrors(props.errors);
  }, [props.errors]);

  const handleChange = name => event => {
    if (
      name === "autoresponder" &&
      event.target.value !== values.autoresponder &&
      event.target.value !== "None"
    ) {
      //if autoresponder changed
      //call API ask for availiable lists
      getIntegration({ integration: event.target.value, action: "lists" });
    }
    if (name.includes("meta")) {
      setValues({
        ...values,
        meta: { ...values.meta, [name.slice(5)]: event.target.value }
      });
    } else setValues({ ...values, [name]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const saveSettings = () => {
    let sendValues = values;
    sendValues.oldPath = pageInfo.path;
    sendValues.stack = pageInfo.stack;
    editPage(sendValues);
  };

  return (
    <div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClickOpen}
        style={{
          margin: 0,
          borderRadius: 0,
          height: "100%",
          boxShadow: "none"
        }}
      >
        Publish
      </Button>
      {open ? (
        <Dialog
          disableBackdropClick
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {pageInfo.title} (/{pageInfo.path})
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ height: 350 }}>
            <div className="spinner" hidden={!loading}></div>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              classes={{
                indicator: classes.indicator
              }}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                className={tab === 0 ? classes.selTab : classes.tab}
                label="Basic"
                {...a11yProps(0)}
              />
              <Tab
                className={tab === 1 ? classes.selTab : classes.tab}
                label="Email"
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Essential Settings</Typography>
                  <br />
                  <MyTextField
                    label="Title"
                    error={errors.title ? true : false}
                    helperText={errors.title}
                    id="standard-start-adornment"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.title}
                    onChange={handleChange("title")}
                  />
                  <br />
                  <MyTextField
                    label="Path"
                    error={errors.path ? true : false}
                    helperText={errors.path}
                    id="standard-start-adornment"
                    className={clsx(classes.margin, classes.textField)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">/</InputAdornment>
                      )
                    }}
                    variant="outlined"
                    value={values.path}
                    onChange={handleChange("path")}
                  />
                  <br />
                  <div style={{display:'flex'}}>
                  <MyTextField
                    label="Favicon Link"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.meta.iconLink}
                    onChange={handleChange("meta.iconLink")}
                  /> 
                  <img alt="favicon" style={{width:45,height:40, marginLeft:5, marginTop:7}} width={40} height={40} src={values.meta.iconLink}/>
                  </div>
                  <br />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">SEO Meta Data</Typography>
                  <br />
                  <MyTextField
                    label="Meta Title"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.meta.title}
                    onChange={handleChange("meta.title")}
                  />
                  <br />
                  <MyTextField
                    multiline
                    rows="3"
                    label="Meta Description"
                    id="standard-start-adornment"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.meta.description}
                    onChange={handleChange("meta.description")}
                  />
                  <br />
                  <MyTextField
                    label="Meta Keywords"
                    id="standard-start-adornment"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.meta.keywords}
                    onChange={handleChange("meta.keywords")}
                  />
                  <br />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Typography variant="h6">Autoresponder Integration</Typography>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MySelect
                    label="Autoresponder"
                    id="autoresponder"
                    value={values.autoresponder}
                    onChange={handleChange("autoresponder")}
                    className={classes.formControl}
                    items={[
                      "None",
                      ...(user.integrations &&
                        user.integrations.map(integration => {
                          if (integration.group === "autoresponder")
                            return integration.name;
                        }))
                    ]}
                  />
                  <p>
                    If you haven't set up an autoresponder yet, you can{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="/settings#integrations"
                    >
                      Integrate Here
                    </a>
                  </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MySelect
                    label="List"
                    id="autoresponderList"
                    value={values.autoresponderList}
                    onChange={handleChange("autoresponderList")}
                    className={classes.formControl}
                    defaultValue={values.autoresponderList}
                    items={
                      lists &&
                      lists.length &&
                      lists.map(list => ({
                        value: list.id,
                        title: list.name
                      }))
                    }
                  />
                  <p>The list you want to add the contacts to.</p>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField
                    label="Tags"
                    id="standard-start-adornment"
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    value={values.autoresponderTags}
                    onChange={handleChange("autoresponderTags")}
                  />
                  <p>Comma separated list of tags to apply to the contact.</p>
                </Grid>
              </Grid>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={saveSettings}
              variant="contained"
              className={classes.button}
            >
              Publish Page
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}

PublishPage.propTypes = {
  getIntegration: PropTypes.func.isRequired,
  editor: PropTypes.object
};

const mapStateToProps = state => ({
  getIntegration: state.getIntegration,
  editor: state.editor
});

export default connect(mapStateToProps, {
  getIntegration
})(PublishPage);
