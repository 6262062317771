const myBlocks = (editor, mainState) => {
  let bm = editor.BlockManager;
  let stylePrefix = "sp-";
  const flexGrid = 1;
  const basicStyle = true;
  const clsRow = `${stylePrefix}row`;
  const clsCell = `${stylePrefix}cell`;
  const styleRow = flexGrid
    ? `
      .${clsRow} {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        width: 100%;
      }
      @media (max-width: 768px) {
        .${clsRow} {
          flex-direction: column;
          flex-wrap: wrap;
        }
      }`
    : `
      .${clsRow} {
        display: table;
        padding: 10px;
        width: 100%;
      }
      @media (max-width: 768px) {
        .${stylePrefix}cell, .${stylePrefix}cell30, .${stylePrefix}cell70 {
          width: 100%;
          display: block;
        }
      }`;
  const styleClm = flexGrid
    ? `
      .${clsCell} {
        min-height: 75px;
        flex-grow: 1;
        flex-basis: 100%;
      }`
    : `
      .${clsCell} {
        width: 33%;
        display: table-cell;
        height: 100%;
      }`;
  const styleClm30 = `
    .${stylePrefix}cell30 {
      width: 30%;
    }`;
  const styleClm70 = `
    .${stylePrefix}cell70 {
      width: 70%;
    }`;

  const step = 0.2;
  const minDim = 1;
  const currentUnit = 1;
  const resizerBtm = {
    tl: 0,
    tc: 0,
    tr: 0,
    cl: 0,
    cr: 0,
    bl: 0,
    br: 0,
    minDim
  };
  const resizerRight = {
    ...resizerBtm,
    cr: 1,
    bc: 0,
    currentUnit,
    minDim,
    step
  };

  // Flex elements do not react on width style change therefore I use
  // 'flex-basis' as keyWidth for the resizer on columns
  if (flexGrid) {
    resizerRight.keyWidth = "flex-basis";
  }

  const rowAttr = {
    class: clsRow,
    "data-gjs-droppable": `.${clsCell}`,
    "data-gjs-resizable": resizerBtm,
    "data-gjs-name": "Row"
  };

  const colAttr = {
    class: clsCell,
    "data-gjs-draggable": `.${clsRow}`,
    "data-gjs-resizable": resizerRight,
    "data-gjs-name": "Cell"
  };

  if (flexGrid) {
    colAttr["data-gjs-unstylable"] = ["width"];
    colAttr["data-gjs-stylable-require"] = ["flex-basis"];
  }

  // Make row and column classes private
  const privateCls = [`.${clsRow}`, `.${clsCell}`];
  editor.on(
    "selector:add",
    selector =>
      privateCls.indexOf(selector.getFullName()) >= 0 &&
      selector.set("private", 1)
  );

  const attrsToString = attrs => {
    const result = [];

    for (let key in attrs) {
      let value = attrs[key];
      const toParse = value instanceof Array || value instanceof Object;
      value = toParse ? JSON.stringify(value) : value;
      result.push(`${key}=${toParse ? `'${value}'` : `"${value}"`}`);
    }

    return result.length ? ` ${result.join(" ")}` : "";
  };

  const attrsRow = attrsToString(rowAttr);
  const attrsCell = attrsToString(colAttr);

  //CHECKOUT//

  bm.add("Checkout Form", {
    label: "Purchase Form",
    category: "Forms",
    attributes: { class: "fa fa-money-bill" },
    content: `
      <style>
      ::-webkit-input-placeholder { /* Edge */
        color: #aab7c4;
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #aab7c4;
      }
      ::placeholder { 
        color: #aab7c4;
        opacity: 1;
      }
      .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
      }
      
      .StripeElement--invalid {
        border-color: #fa755a !important;
      }
      
      .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
      }
      .order-summary {
        margin-top:7px;
        margin-bottom:7px;
      }
      </style>
      <form method="post" action="https://${mainState.domain}/c/${mainState.id}" id="payment-form">
      <input type="hidden" name="redirect" value="" />
      <input type="hidden" name="stackId" value="${mainState.stack}" />
      <input type="hidden" name="product" value="" />
      <input name="name" required type="name" placeholder="Enter Your Name"/>
      <input name="email" required type="email" placeholder="Enter Your Best Email Address"/>
          <div data-gjs-removable="false" id="card-element"></div>
          <div data-gjs-removable="false" id="card-errors" role="alert" style="color:red;font:inherit;}"></div>
          <div class="order-summary">Order Summary:</div>
          <div data-gjs-removable="false" data-gjs-draggable="false" propagate="['draggable','removable']" id="product-display">
            <table id="product-table" data-gjs-removable="false" data-gjs-selectable="false" propagate="['selectable','removable']">
            <tbody>
            <tr><th>
                Item
              </th><th>
                Amount
              </th></tr><tr><td>
                Select a Product
              </td><td>
                $0.00
              </td></tr></tbody></table>
          </div>
        <button data-gjs-removable="false"  next-step="nextPage">Submit Payment</button>
        </form>
      `
  });

  //FORMS //

  bm.add("form", {
    label: `
  <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <rect class="gjs-block-svg-path" x="2" y="15" width="10" height="3" rx="0.5"></rect>
    <path class="gjs-block-svg-path" d="M22,5.5 C22,5.2 21.5,5 20.75,5 L3.25,5 C2.5,5 2,5.2 2,5.5 L2,8.5 C2,8.8 2.5,9 3.25,9 L20.75,9 C21.5,9 22,8.8 22,8.5 L22,5.5 Z M21,8 L3,8 L3,6 L21,6 L21,8 Z" fill-rule="nonzero"></path>
    <path class="gjs-block-svg-path" d="M22,10.5 C22,10.2 21.5,10 20.75,10 L3.25,10 C2.5,10 2,10.2 2,10.5 L2,13.5 C2,13.8 2.5,14 3.25,14 L20.75,14 C21.5,14 22,13.8 22,13.5 L22,10.5 Z M21,13 L3,13 L3,11 L21,11 L21,13 Z" fill-rule="nonzero"></path>
  </svg>
  <div class="gjs-block-label">Email Form</div>`,
    category: "Forms",
    content: [
      {
        tagName: "form",
        type: "Email Form",
        status: "hovered",
        activeOnRender: 1,
        content: "",
        attributes: {
          method: "post",
          action: `https://${mainState.domain}/p/${mainState.id}`
        },
        components: [
          {
            type: "input",
            void: true,
            content: "",
            attributes: {
              name: "name",
              placeholder: "Enter Your Name"
            },
            open: false
          },
          {
            type: "input",
            void: true,
            content: "",
            attributes: {
              type: "hidden",
              name: "redirect",
              value: ""
            },
            open: false
          },
          {
            type: "input",
            void: true,
            content: "",
            attributes: {
              type: "hidden",
              name: "stackId",
              value: mainState.stack
            },
            open: false
          },
          {
            type: "input",
            void: true,
            content: "",
            attributes: {
              required: "",
              name: "email",
              type: "email",
              placeholder: "Enter Your Best Email Address"
            },
            open: false
          },
          {
            type: "button",
            content: "Submit Form",
            activeOnRender: 1,
            attributes: {
              "submit-form": "submitForm",
              "next-step": "nextPage"
            },
            open: false
          }
        ],
        open: false
      }
    ]
  });

  bm.add("input", {
    label: `
      <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z"></path>
        <polygon class="gjs-block-svg-path" points="4 10 5 10 5 14 4 14"></polygon>
      </svg>
      <div class="gjs-block-label">Input Field</div>`,
    category: "Forms",
    select: true,
    content: { activeOnRender: 1, type: "input" }
  });

  bm.add("1-column", {
    label: "Row",
    category: "Basic",
    attributes: { class: "gjs-fonts gjs-f-b1" },
    content: `<div ${attrsRow}>
          <div ${attrsCell} style="${
      flexGrid ? "flex-basis" : "width"
    }: 100%;"></div>          
        </div>
        ${
          basicStyle
            ? `<style>
            ${styleRow}
            ${styleClm}
            ${styleClm30}
            ${styleClm70}
          </style>`
            : ""
        }`
  });

  bm.add("2-column", {
    label: "Two Columns",
    category: "Basic",
    attributes: { class: "gjs-fonts gjs-f-b37" },
    content: `<div ${attrsRow}>
          <div ${attrsCell} style="${
      flexGrid ? "flex-basis" : "width"
    }: 30%;"></div>
          <div ${attrsCell} style="${
      flexGrid ? "flex-basis" : "width"
    }: 70%;"></div>
        </div>
        ${
          basicStyle
            ? `<style>
            ${styleRow}
            ${styleClm}
            ${styleClm30}
            ${styleClm70}
          </style>`
            : ""
        }`
  });

  bm.add("3-column", {
    label: "Three Columns",
    category: "Basic",
    attributes: { class: "gjs-fonts gjs-f-b3" },
    content: `<div ${attrsRow}>
          <div ${attrsCell}></div>
          <div ${attrsCell}></div>
          <div ${attrsCell}></div>
        </div>
        ${
          basicStyle
            ? `<style>
            ${styleRow}
            ${styleClm}
          </style>`
            : ""
        }`
  });
  bm.add("text", {
    label: "Text",
    category: "Basic",
    attributes: { class: "gjs-fonts gjs-f-text" },
    content: {
      type: "text",
      content: "Insert your text here",
      style: { padding: "10px" },
      activeOnRender: 1
    }
  });

  bm.add("button", {
    label: `
      <svg class="gjs-block-svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path class="gjs-block-svg-path" d="M22,9 C22,8.4 21.5,8 20.75,8 L3.25,8 C2.5,8 2,8.4 2,9 L2,15 C2,15.6 2.5,16 3.25,16 L20.75,16 C21.5,16 22,15.6 22,15 L22,9 Z M21,15 L3,15 L3,9 L21,9 L21,15 Z" fill-rule="nonzero"></path>
        <rect class="gjs-block-svg-path" x="4" y="11.5" width="16" height="1"></rect>
      </svg>
      <div class="gjs-block-label">Button</div>`,
    category: "Basic",
    select: true,
    content: {
      tagName: "a",
      type: "Link Button",
      content: "I'm SO Clickable!",
      activeOnRender: 1,
      attributes: {
        href: "https://stackpages.io",
        target: "_blank",
        rel: "noopener",
        "button-link": "https://google.com"
      }
    }
  });

  bm.add("image", {
    label: "Image",
    category: "Basic",
    attributes: { class: "gjs-fonts gjs-f-image" },
    content: {
      style: { color: "black", width: "100%" },
      type: "image",
      activeOnRender: 1
    }
  });

  bm.add("video", {
    category: "Basic",
    label: "Video",
    attributes: { class: "fa fa-youtube-play" },
    select: true,
    content: {
      type: "video",
      activeOnRender: 1,
      attributes: { allowfullscreen: "allowfullscreen" },
      src: "https://www.youtube.com/embed/G1IbRujko-A?",
      provider: "yt",
      open: false
    }
  });

  bm.add("map", {
    label: "Map",
    category: "Basic",
    attributes: { class: "fa fa-map-o" },
    content: {
      type: "map",
      style: { height: "350px" }
    }
  });

  bm.add("Custom Code", {
    label: "Custom HTML",
    category: "Advanced",
    attributes: { class: "fa fa-code" },
    activate: true,
    select: true,
    traits: [
      // Strings are automatically converted to text types
      "name", // Same as: { type: 'text', name: 'name' }
      "placeholder",
      { type: "checkbox", name: "required" }
    ],
    content: { type: "custom-code", style: { height: "45px" } }
  });
};

export { myBlocks };
