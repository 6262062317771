import React, { Fragment,Suspense, lazy } from "react";
import { Route, Switch} from "react-router-dom";
//cannot lazyload Editor.. throws massive issue and unstyles the whole page :(
  import Editor from  "../editor/Editor";
// const Editor = lazy(()=> import( "../editor/Editor")); 

//load everything else
const Navbar = lazy(()=> import(  "./../layout/Navbar"));
const Landing = lazy(()=> import(  "./../frontface/Landing"));
const Pricing = lazy(()=> import(  "./../frontface/Pricing"));
const Register = lazy(()=> import( "../auth/Register"));
const Login = lazy(()=> import( "../auth/Login"));
const NotFound = lazy(()=> import( "../layout/NotFound"));
const PrivateRoute = lazy(()=> import( "../routing/PrivateRoute"));
const PrivacyPolicy = lazy(()=> import( "./../frontface/PrivacyPolicy"));
const Settings = lazy(()=> import( "./../settings/Settings"));
const Stack = lazy(()=> import( "./../stack/Stack"));
const Users = lazy(()=> import( "./../users/Users"));
const Admin = lazy(()=> import( "./../admin/Admin"));
const Products = lazy(()=> import( "./../products/Products"));
const Templates = lazy(()=> import( "./../templates/Templates"));
const Welcome = lazy(()=> import( "./../welcome/Welcome"));
const Dashboard = lazy(()=> import("../dashboard/Dashboard"))

const NonNavRoutes = () => {
  return (
    <Fragment>
      <section className="editor_container">
        <PrivateRoute path="/editor" component={Editor} />
      </section>
    </Fragment>
  );
};

const containerRoutes = () => {
  return (
    <div className="container">
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/stack/:id" component={Stack} />
        <PrivateRoute exact path="/stacks" component={Dashboard} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/templates" component={Templates} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/editor" component={NonNavRoutes} />
        <PrivateRoute exact path="/welcome" component={Welcome} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

const NavRoutes = () => {
  return (
    <Fragment>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-service" component={PrivacyPolicy} />
        <Route exact path="/pricing" component={Pricing} />
        <Route component={containerRoutes} />
      </Switch>
    </Fragment>
  );
};

const Routes = () => {
  return (
    <Suspense fallback={<div className="spinner"></div>}>
    <Switch>
      <PrivateRoute exact path="/editor" component={NonNavRoutes} />
      <Route component={NavRoutes} />
    </Switch>
    </Suspense>
  );
};

export default Routes;
