const importTemplate = editor => {
  const pfx = editor.getConfig("stylePrefix");
  const modal = editor.Modal;
  const codeViewer = editor.CodeManager.getViewer("CodeMirror").clone();
  const container = document.createElement("div");
  const importLabel = "";
  const importCnt = "";
  let viewerEditor = codeViewer.editor;

  // Init import button
  const btnImp = document.createElement("button");
  btnImp.type = "button";
  btnImp.innerHTML = "Import";
  btnImp.className = `${pfx}btn-prim ${pfx}btn-import`;
  btnImp.onclick = e => {
    editor.setComponents(viewerEditor.getValue().trim());
    modal.close();
  };

  // Init code viewer
  codeViewer.set({
    codeName: "htmlmixed",
    theme: "hopscotch",
    readOnly: 0
  });

  return {
    run(editor) {
      if (!viewerEditor) {
        const txtarea = document.createElement("textarea");

        if (importLabel) {
          const labelEl = document.createElement("div");
          labelEl.className = `${pfx}import-label`;
          labelEl.innerHTML = importLabel;
          container.appendChild(labelEl);
        }

        container.appendChild(txtarea);
        container.appendChild(btnImp);
        codeViewer.init(txtarea);
        viewerEditor = codeViewer.editor;
      }

      modal.setTitle("Import");
      modal.setContent(container);
      const cnt =
        typeof importCnt == "function" ? importCnt(editor) : importCnt;
      codeViewer.setContent(cnt || "");
      modal
        .open()
        .getModel()
        .once("change:open", () => editor.stopCommand(this.id));
      viewerEditor.refresh();
    },

    stop() {
      modal.close();
    }
  };
};

const myCommands = editor => {
  // Define commands
  editor.Commands.add("show-blocks", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getLayersEl(row) {
      return row.querySelector(".blocks-container");
    },

    run(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "";
    },
    stop(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "none";
    }
  });
  editor.Commands.add("show-layers", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getLayersEl(row) {
      return row.querySelector(".layers-container");
    },

    run(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "";
    },
    stop(editor, sender) {
      const lmEl = this.getLayersEl(this.getRowEl(editor));
      lmEl.style.display = "none";
    }
  });
  editor.Commands.add("show-styles", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getStyleEl(row) {
      return row.querySelector(".styles-container");
    },

    run(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "";
    },
    stop(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "none";
    }
  });

  editor.Commands.add("show-settings", {
    getRowEl(editor) {
      return editor.getContainer().closest(".editor-row");
    },
    getStyleEl(row) {
      return row.querySelector(".settings-container");
    },

    run(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));

      smEl.style.display = "";
    },
    stop(editor, sender) {
      const smEl = this.getStyleEl(this.getRowEl(editor));
      smEl.style.display = "none";
    }
  });

  editor.Commands.add("import-html", importTemplate(editor));

  editor.Commands.add("set-device-desktop", {
    run: function(ed) {
      ed.setDevice("Desktop");
    },
    stop: function() {}
  });
  editor.Commands.add("set-device-tablet", {
    run: function(ed) {
      ed.setDevice("Tablet");
    },
    stop: function() {}
  });
  editor.Commands.add("set-device-mobile", {
    run: function(ed) {
      ed.setDevice("Mobile portrait");
    },
    stop: function() {}
  });
};

export { myCommands };
