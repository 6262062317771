export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_ERRORS = "SET_ERRORS";
export const LOADED_DATA = "LOADED_DATA";
export const LOADING_DATA = "LOADING_DATA";
export const AUTH_LOADED = "AUTH_LOADED";
export const AUTH_LOADING = "AUTH_LOADING";
export const PURCHASE_SUCCESS = " PURCHASE_SUCCESS";
