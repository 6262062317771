import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Routes from "./components/routing/Routes";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// Redux
import { Provider } from "react-redux";

import store from "./store";
// import { loadUser } from "./actions/auth";

import setAuthToken from "./utils/setAuthToken";
import { primary, secondary } from "./uitheme";
import "./App.css";

import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";

import ReactGA from "react-ga";

ReactGA.initialize("UA-156193713-1");

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

//Mat UI Global Theme
const theme = createMuiTheme({
  palette: {
    primary: { main: primary, contrastText: "white" },
    secondary: { main: secondary, contrastText: "black" }
  },
  typography: {
    fontFamily: "Raleway, sans-serif",
    button: {
      textTransform: "none",
      fontSize: "15px"
    },
    colorTextSecondary: {
      color: "#fff"
    }
  }
});

const App = () => {
  return (
    <Provider store={store}>
      <Alert stack={{ limit: 1 }} position="bottom" effect="stackslide" />
      <Router>
        <MuiThemeProvider theme={theme}>
          <Route component={Routes} />
          <Route
            path="/"
            render={({ location }) => {
              if (process.env.NODE_ENV && process.env.NODE_ENV !== "development")
                ReactGA.pageview(location.pathname + location.search);
              return null;
            }}
          />
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;
