import { defaultStyles } from "./styles";
import Alert from "react-s-alert";
//Stripe Checkout Plugin
export const myComponents = (editor, mainState) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType("default");
  const defaultView = defaultType.view;
  const defaultModel = defaultType.model;
  const switchSettings = () => {
    const switchButton = editor.Panels.getButton(
      "panel-switcher",
      "show-settings"
    );
    switchButton.set("active", 1);
  };

  domc.addType("text", {
    model: {
      defaults: {
        unstylable: [
          "font-family",
          "font-size",
          "font-weight",
          "letter-spacing",
          "color",
          "line-height",
          "text-align",
          "text-shadow"
        ]
      }
    }
  });

  domc.addType("video", {
    extend: "video",
    model: {
      defaults: {
        ...defaultModel.prototype.defaults,
        style: {
          width: "100%",
          height: "180px",
          "max-width": "330px"
        },
        resizable: true,
        type: "video",
        tagName: "iframe",
        videoId: "",
        void: 0,
        provider: "yt", // on change of provider, traits are switched
        ytUrl: "https://www.youtube.com/embed/",
        ytncUrl: "https://www.youtube-nocookie.com/embed/",
        viUrl: "https://player.vimeo.com/video/",
        loop: 0,
        poster: "",
        autoplay: 0,
        controls: 1,
        color: "",
        sources: [],
        attributes: { allowfullscreen: "allowfullscreen" },
        toolbar: defaultModel.prototype.defaults.toolbar
      },
      /**
       * Return the provider trait
       * @return {Object}
       * @private
       */
      getProviderTrait() {
        return {
          type: "select",
          label: "Provider",
          name: "provider",
          changeProp: 1,
          options: [
            { value: "yt", name: "Youtube" },
            { value: "vi", name: "Vimeo" }
          ]
        };
      },

      updateTraits() {
        var prov = this.get("provider");
        var traits = this.getSourceTraits();
        switch (prov) {
          case "yt":
            traits = this.getYoutubeTraits();
            break;
          case "vi":
            traits = this.getVimeoTraits();
            break;
          default:
            traits = this.getYoutubeTraits();
            break;
        }
        this.loadTraits(traits);
        this.em.trigger("component:toggled");
        this.setStyle(this.getStyle());
      },

      getYoutubeTraits() {
        return [
          this.getProviderTrait(),
          {
            label: "Video ID",
            name: "videoId",
            placeholder: "eg. jNQXAC9IVRw",
            changeProp: 1
          },
          this.getAutoplayTrait(),
          this.getLoopTrait(),
          this.getControlsTrait()
        ];
      },

      /**
       * Return traits for the source provider
       * @return {Array<Object>}
       * @private
       */
      getVimeoTraits() {
        return [
          this.getProviderTrait(),
          {
            label: "Video ID",
            name: "videoId",
            placeholder: "eg. 123456789",
            changeProp: 1
          },
          {
            label: "Color",
            name: "color",
            placeholder: "eg. FF0000",
            changeProp: 1
          },
          this.getAutoplayTrait(),
          this.getLoopTrait()
        ];
      },

      init() {
        this.on("active", switchSettings);
      }
    }
  });

  domc.addType("custom-code", {
    isComponent: el => el.tagName === "Custom Code",
    model: {
      defaults: {
        traits: [
          // Strings are automatically converted to text types
          "id", // Same as: { type: 'text', name: 'name' }
          {
            type: "button",
            // ...
            text: "Edit Custom HTML",
            full: true, // Full width button
            // or you can just specify the Command ID
            command: "custom-code:open-modal"
          }
        ],
        // As by default, traits are binded to attributes, so to define
        // their initial value we can use attributes
        attributes: { type: "text", required: true }
      }
    }
  });
  domc.removeType("form");
  domc.addType("Email Form", {
    isComponent(el) {
      if (el.tagName === "FORM" && el.id !== "payment-form") {
        return { type: "Email Form" };
      }
    },
    model: {
      defaults: {
        traits: []
      },
      init() {
        this.on("active", switchSettings);
        this.addAttributes({
          action: `https://${mainState.domain}/p/${mainState.id}`
        });
      }
    }
  });

  // INPUT
  domc.addType("input", {
    model: {
      defaults: {
        style: defaultStyles.inputField,
        traits: [
          {
            name: "name",
            label: "Type",
            type: "select",
            options: [
              { value: "name", name: "Name" },
              { value: "email", name: "Email" },
              { value: "text", name: "Text" },
              { value: "number", name: "Number" },
              { value: "password", name: "Password" }
            ]
          },
          {
            name: "placeholder",
            label: "Placeholder"
          },
          {
            type: "checkbox",
            name: "required",
            label: "Required"
          }
        ]
      },
      init() {
        this.on("active", switchSettings);
        this.on("change:attributes:name", this.handleTypeChange);
      },

      handleTypeChange() {
        //change the 'type' to match the 'name'
        this.addAttributes({ type: this.getAttributes().name });
      }
    }
  });

  domc.addType("Link Button", {
    model: {
      defaults: {
        style: defaultStyles.defaultButton,
        traits: [
          {
            type: "content",
            label: "Text"
          },
          {
            name: "button-link",
            label: "Link"
          },
          {
            name: "target",
            label: "New Tab?",
            type: "checkbox"
          }
        ]
      },
      init() {
        this.on("active", switchSettings);
        this.on("change:attributes:button-link", this.handleLinkChange);
        this.on("change:attributes:target", this.handleTargetChange);
      },
      handleLinkChange() {
        const thisLink = this.getAttributes()["button-link"];
        this.addAttributes({
          href: thisLink
        });
      },
      handleTargetChange() {
        if (this.getAttributes()["target"])
          //if checked
          this.addAttributes({
            target: "_blank"
          });
        else
          this.addAttributes({
            target: ""
          });
      }
    },
    view: defaultView.extend({
      events: {
        click: "handleClick"
      },

      handleClick(e) {
        e.preventDefault();
      }
    })
  });

  domc.addType("button", {
    model: {
      defaults: {
        style: defaultStyles.button,
        traits: [
          {
            type: "content",
            label: "Text"
          },
          {
            type: "select",
            label: "Next Page",
            name: "next-step",
            options: [
              { value: "nextPage", name: "In Stack" },
              { value: "customPage", name: "Custom Link" }
            ]
          },
          {
            name: "show-next-step",
            type: "show-next-step"
          }
        ]
      },
      init() {
        this.on("active", switchSettings);
        //add link input if already showing
        if (this.getAttributes()["next-step"] === "customPage") {
          this.addTrait({
            name: "custom-link",
            label: "External Link"
          });
        }
        this.on("change:attributes:next-step", this.handleStepChange);
        this.on("change:attributes:custom-link", this.handleLinkChange);
      },
      handleLinkChange() {
        const redirectInput = this.parent().find("[name=redirect]")[0];
        redirectInput &&
          redirectInput.addAttributes({
            value: this.getAttributes()["custom-link"]
          });
      },

      handleStepChange() {
        const redirectInput = this.parent().find("[name=redirect]")[0];
        const whichStep = this.getAttributes()["next-step"];
        if (whichStep === "nextPage") {
          redirectInput && redirectInput.addAttributes({ value: "" });
          this.removeTrait("custom-link");
          this.addTrait({
            name: "show-next-step",
            label: mainState.stack,
            type: "show-next-step"
          });
        } else {
          redirectInput &&
            redirectInput.addAttributes({
              value: this.getAttributes()["custom-link"]
            });
          this.removeTrait("show-next-step");
          this.addTrait({
            name: "custom-link",
            label: "External Link"
          });
        }
      }
    }
  });

  domc.addType("Checkout Form", {
    // Make the editor understand when to bind `simple-checkout`
    isComponent: el => el.id === "payment-form",
    // Model definition
    model: {
      // Default properties
      defaults: {
        style: defaultStyles.stripeForm,
        tagName: "form",
        attributes: {
          // Default attributes
          // type: "form",
          id: "payment-form",
          name: "Checkout Form",
          product: "None"
        },
        content: `<script src="https://js.stripe.com/v3/"></script>`,
        script: `if (typeof Stripe !== 'undefined'){
            var stripe = Stripe("pk_live_V1VrHK6iptDEbyJG8MwQfcBw");
            var elements = stripe.elements();
            var style = {
              base: {
                color: '#32325d',
                fontFamily: 'Arial',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
              }
            };
            
            var card = elements.create('card', {style: style});
            
            card.mount('#card-element');
            
            card.addEventListener('change', function(event) {
              var displayError = document.getElementById('card-errors');
              if (event.error) {
                displayError.textContent = event.error.message;
              } else {
                displayError.textContent = '';
              }
            });
            
            var form = document.getElementById('payment-form');
            form.addEventListener('submit', function(event) {
              event.preventDefault();
              stripe.createToken(card).then(function(result) {
                if (result.error) {
                  var errorElement = document.getElementById('card-errors');
                  errorElement.textContent = result.error.message;
                } else {
                  stripeTokenHandler(result.token);
                }
              });
            });
          }
          
          // Submit the form with the token ID.
          function stripeTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);
            form.submit();
          }`,
        traits: [
          {
            name: "product",
            label: "Product",
            type: "select",
            options: mainState.products.map(product => {
              return {
                value: product._id,
                name: `${product.name} - ${product.cost} ${product.currency}`
              };
            })
          },
          {
            name: "add-product",
            label: "add-product",
            type: "add-product"
          }
        ]
      },
      init() {
        this.on("active", switchSettings);
        this.addAttributes({
          method: "POST",
          action: `https://${mainState.domain}/c/${mainState.id}`
        });
        this.on("change:attributes:product", () => {
          //add the product as an element in the checkout form
          let productDiv = this.find("#product-display")[0];
          const productId = this.getAttributes("product").product;
          const product = mainState.products.find(
            product => product._id === productId
          );
          if (product) {
            //add product id as hidden input
            const productInput = this.find("[name=product]")[0];
            productInput &&
              productInput.addAttributes({
                value: product._id
              });

            if (productDiv)
              productDiv.components(`            
            <table id="product-table" data-gjs-removable="false" data-gjs-selectable="false" propagate="['selectable','removable']">
            <tbody>
            <tr><th>
                Item
              </th><th>
                Amount
              </th></tr><tr><td>
                ${product.name}
              </td><td>
                $${product.cost.toFixed(2)}
              </td></tr></tbody></table>`);
          }
        });
      }
    }
  });
  editor.on("component:add", component => {
    if (component.is("Checkout Form")) {
      const wrapper = editor.getWrapper();
      const el = wrapper.find("#payment-form-2")[0];
      if (el) {
        component.remove();
        Alert.error("Only one payment form is allowed per page");
      }
      if (mainState.domain.endsWith(".stackpages.io")) {
        component.remove();
        Alert.error("You need a custom domain to handle purchases!");
      }
    }
  });
};
