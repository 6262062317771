import { defaultStyles } from "./styles";

//Stripe Checkout Plugin
export const StripePlugin = editor => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType("default");
  const defaultView = defaultType.view;

  domc.addType("Checkout Form", {
    // Make the editor understand when to bind
    isComponent: el => el.id === "payment-form"
  });

  domc.addType("Credit Card Input", {
    isComponent: el => el.id === "card-element",
    model: {
      defaults: {
        style: defaultStyles.inputField,
        name: "Credit Card Input",
        tagName: "div",
        draggable: false,
        droppable: false,
        traits: [
          {
            name: "add-product",
            label: "add-product",
            type: "add-product"
          }
        ]
      }
    },
    view: defaultView.extend({
      onRender({ el }) {
        const span = document.createElement('span');
        span.innerHTML = 'Credit card form will load here...';
        el.appendChild(span);
      },
    })
  });

  domc.addType("Product Display", {
    isComponent: el => el.id === "product-display",
    model: {
      defaults: {
        name: "Product Display",
        tagName: "div",
        draggable: false,
        droppable: false,
        removable: false,
        copyable: false,
        traits: []
      }
    }
  });

  domc.addType("Credit Card Input", {
    isComponent: el => el.id === "card-element",
    model: {
      defaults: {
        style: defaultStyles.inputField,
        name: "Credit Card Input",
        tagName: "span",
        draggable: false,
        droppable: false,
        selectable: false,
        traits: []
      }
    }
  });

  domc.addType("Product Table", {
    isComponent: el => el.id === "product-table",
    model: {
      defaults: {
        tagName: "table",
        selectable: false,
        draggable: false,
        droppable: false,
        removable: false,
        copyable: false,
        propagate: [
          "removable",
          "draggable",
          "droppable",
          "copyable",
          "selectable"
        ],
        style: defaultStyles.productField
      }
    }
  });
};

export const customCss = editor => {
  const sm = editor.StyleManager;
  sm.addType("custom-css", {
    view: {
      templateInput(model) {
        return `<textarea class="${this.ppfx}field" style="padding:3px;width:200%;height:150px;resize: none;color:white;" >hi</textarea>`;
      }
    },
    model: {
      init() {
        this.on("change", () => {
          const appliedCss = {color:"red"};
          const cssToApply = appliedCss;
          const selComp = editor.getSelected();
          console.log(cssToApply)
          if (selComp) {
            let selCompStyle = { ...selComp.getStyle(), ...cssToApply };
            console.log(selCompStyle);
            selComp.setStyle(selCompStyle);
            console.log("done?");
          }
        });
      }
    }
  });
};
