import { combineReducers } from 'redux';
import errors from './errors';
import auth from './auth';
import editor from './editor';

export default combineReducers({
  errors,
  auth,
  editor
});
