import { TextField, MenuItem } from "@material-ui/core";
import React from "react";
// import useInvisibleRefWidth from "./getInputWidth";
//to avoid the line through the label, we need to reinvoke the textfield is the label/state is change.
//This will give it a static label and compute the distance to open the line up!
function useInvisibleRefWidth(ref) {
  // initialize with undefined or offsetWidth
  const [labelWidth, setLabelWidth] = React.useState(
    ref.current && ref.current.offsetWidth
  );
  React.useEffect(() => {
    const { offsetWidth, innerText } = ref.current;
    if (!labelWidth && ref.current && ref.current.offsetWidth) {
      setLabelWidth(ref.current.offsetWidth);
    } else if (!offsetWidth && innerText && innerText.length > 0) {
      const temporarySpan = ref.current.cloneNode();
      temporarySpan.innerText = innerText;
      document.body.appendChild(temporarySpan);
      // we set state only if it's changing
      if (labelWidth !== temporarySpan.offsetWidth) {
        setLabelWidth(temporarySpan.offsetWidth);
      }
      document.body.removeChild(temporarySpan);
    }
  });
  return labelWidth;
}

function MySelect(props) {
  const inputLabel = React.useRef();
  const labelWidth = useInvisibleRefWidth(inputLabel, {});
  return (
    <TextField
      style={props.style}
      select
      key={props.id}
      fullWidth={props.fullWidth}
      className={props.className}
      variant={props.variant || "outlined"}
      margin={props.margin || "dense"}
      name={props.name}
      disabled={props.disabled}
      helperText={props.helperText}
      error={props.error}
      label={props.label}
      id={props.id}
      InputProps={{
        labelWidth
      }}
      InputLabelProps={{
        ref: inputLabel
      }}
      value={props.value}
      onChange={props.onChange}
      minLength={props.minLength}
      maxLength={props.maxLength}
      type={props.type}
      autoComplete={props.autoComplete}
      autoFocus={props.autoFocus}
      required={props.required}
    >
      {props.items && props.items.length ? (
        props.items.map(item => (
          <MenuItem
            key={(item && item.value) || item}
            value={(item && item.value) || item}
          >
            {(item && item.title) || item}
          </MenuItem>
        ))
      ) : props.defaultValue ? (
        <MenuItem key={props.defaultValue} value={props.defaultValue}>
          {props.defaultValue}
        </MenuItem>
      ) : (
        <MenuItem key={"none"} value={"none"}>
          None
        </MenuItem>
      )}
    </TextField>
  );
}

export default MySelect;
