/* eslint-disable no-throw-literal */
import axios from "axios";
import { LOADED_DATA, SET_ERRORS, LOADING_DATA } from "./types";
import imageCompression from "browser-image-compression";
import { gaAction } from "../utils/ReactGA";

// get users products
export const fetchProducts = stackId => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get(`/api/editor/product/${stackId}`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// delete user product
export const deleteProduct = productId => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.delete(`/api/editor/product/${productId}`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// add user product
export const addProduct = productInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/editor/product`, productInfo);
    gaAction({ category: "User", action: "Added Product" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Edit user stack
export const editStack = stackInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/editor/stack`, stackInfo);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Remove user stack
export const deleteStack = (domain, stackId) => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.delete(`/api/editor/stack/${domain}/${stackId}`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Remove user page
export const deletePage = (domain, pageId, stackId) => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.delete(
      `/api/editor/page/${domain}/${pageId}/${stackId}`
    );
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

//fetch one or all pages for a user
export const fetchPages = pageId => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    let res;
    if (pageId) {
      res = await axios.get(`/api/editor/fetchPages/${pageId}`).catch(err => {
        throw err.response.data;
      });
    } else {
      res = await axios.get("/api/editor/fetchPages").catch(err => {
        throw err.response.data;
      });
    }
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};

//fetch one or all stacks for a user
export const fetchStacks = pageInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    let res;
    if (pageInfo) {
      res = await axios.post("/api/editor/fetchStacks", pageInfo).catch(err => {
        throw err.response.data;
      });
    } else {
      res = await axios.get("/api/editor/fetchStacks").catch(err => {
        throw err.response.data;
      });
    }
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};

// publish content to cloud
export const publishContent = pageInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios
      .post("/api/editor/publishContent", pageInfo)
      .catch(err => {
        throw err.response.data;
      });
    gaAction({ category: "User", action: "Published Page" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};
// Add stack to user's account
export const addStack = pageInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios
      .post("/api/editor/addStack", pageInfo)
      .catch(err => {
        throw err.response.data;
      });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};

// Add page to user's account
export const addPage = pageInfo => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post("/api/editor/addPage", pageInfo).catch(err => {
      throw err.response.data;
    });
    gaAction({ category: "User", action: "Added Page" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};
// Remove Asset from S3
export const removeAsset = assetName => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios
      .post("/api/editor/removeAsset", {
        assetName
      })
      .catch(err => {
        throw err.response.data;
      });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};

// Upload Image API
export const imageUpload = image => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });

    if (image.size / 1024 / 1024 > 1)
      //compress image if over 1MB
      image = await imageCompression(image, {
        maxSizeMB: 1,
        maxWidthOrHeight: 2000
      }).catch(() => {
        throw { invalidType: "Invalid file type. Only images allowed" };
      });
    const signedUrlRes = await axios
      .post("/api/editor/imageUploadRequest", {
        name: image.name,
        type: image.type
      })
      .catch(err => {
        throw err.response.data;
      });
    await axios
      .put(signedUrlRes.data.signedRequest, image, {
        headers: {
          "Content-Type": image.type
        }
      })
      .catch(err => {
        console.log(err);
        throw { invalidType: "Issue uploading file, try again..." };
      });

    //ADD Info to MongoDB.. return image info
    var img = new Image();

    img.onload = async function() {
      signedUrlRes.data.height = img.height;
      signedUrlRes.data.width = img.width;
      signedUrlRes.data.size = image.size;
      const uploadToDB = await axios
        .post("/api/editor/imageUploadDB", signedUrlRes.data)
        .catch(err => {
          throw err.response.data;
        });
      gaAction({ category: "User", action: "Added Image" });
      dispatch({
        type: LOADED_DATA,
        payload: uploadToDB.data
      });
    };

    img.src = signedUrlRes.data.src;
  } catch (err) {
    //err must be in the format {errorname:"error reason"}
    dispatch({
      type: SET_ERRORS,
      payload: err
    });
  }
};
