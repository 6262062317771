const myPanels = (editor, user) => {
  var pn = editor.Panels;
  //Add top panel

  pn.addPanel({
    id: "more-actions",
    el: ".panel__more-actions",
    buttons: [
      {
        id: "vis",
        className: "fa fa-eye",
        command: "sw-visibility"
      },
      {
        id: "undo",
        className: "fa fa-undo",
        attributes: { title: "Undo" },
        command: function() {
          editor.runCommand("core:undo");
        }
      },
      {
        id: "redo",
        className: "fa fa-repeat",
        attributes: { title: "Redo" },
        command: function() {
          editor.runCommand("core:redo");
        }
      }
    ]
  });

  if (user && user.admin)
    editor.Panels.addButton("more-actions", {
      id: "show-json",
      className: "fab fa-js-square",
      attributes: { title: "Export as JSON" },
      command(editor) {
        editor.Modal.setTitle("Style & Components")
          .setContent(
            `<textarea style="width:100%; height: 200px;background-color:black;color:white">
          ${JSON.stringify(editor.getStyle())}
          </textarea><textarea style="width:100%; height: 200px;background-color:black;color:white"">
          ${JSON.stringify(editor.getComponents())}
          </textarea>`
          )
          .open();
      }
    });

  if (user && user.admin)
    editor.Panels.addButton("more-actions", {
      id: "import",
      className: "fa fa-upload",
      attributes: { title: "Import" },
      command: function() {
        editor.runCommand("import-html");
      }
    });

  pn.addPanel({
    id: "panel-view-actions",
    el: ".panel__view-actions",
    buttons: [
      {
        id: "set-device-desktop",
        className: "fa fa-desktop",
        attributes: { title: "Deskop" },
        command: "set-device-desktop",
        active: false
      },
      {
        id: "set-device-mobile",
        className: "fa fa-mobile",
        attributes: { title: "Mobile" },
        command: "set-device-mobile",
        active: false
      }
    ]
  });

  pn.addPanel({
    id: "panel-top",
    el: ".panel__top"
  });

  var titles = document.querySelectorAll("*[title]");
  for (var i = 0; i < titles.length; i++) {
    var el = titles[i];
    var title = el.getAttribute("title");
    title = title ? title.trim() : "";
    if (!title) break;
    el.setAttribute("data-tooltip", title);
    el.setAttribute("title", "");
  }
};

export { myPanels };
