import {
  LOADED_DATA,
  LOADING_DATA,
  SET_ERRORS,
  PURCHASE_SUCCESS
} from "../actions/types";

const initialState = {
  loading: false,
  data: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADED_DATA:
      return {
        ...state,
        loading: false,
        data: payload
      };
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };
    case SET_ERRORS:
    case PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
