//moved old 'componenets' to actual components file
const myTraits = (editor, mainState) => {
  //trait for having a 'add product' button
  editor.TraitManager.addType("add-product", {
    /**
     * Returns the input element
     * @return {HTMLElement}
     */

    noLabel: true,
    templateInput: "",
    events: {},
    createInput({ trait }) {
      return `<div>Not seeing the product you want? Click the button below, create a new product for this stack, and then come back here! :) 
      <button onclick="window.open('/products','_blank')" class="gjs-button">Create New Product</button></div>`;
    }
  });
  editor.TraitManager.addType("show-next-step", {
    noLabel: true,
    templateInput: "",
    events: {},
    createInput({ trait }) {
      return `<div>If you want to add/edit the next step in this stack, click the button below.
      <button onclick="window.open('/stack/${mainState.stack}','_blank')" class="gjs-button">Add/Edit Stack</button></div>`;
    }
  });
};

export { myTraits };
