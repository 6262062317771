import { SET_ERRORS, LOADING_DATA, AUTH_LOADING } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ERRORS:
      return payload;
    case LOADING_DATA:
    case AUTH_LOADING:
      return {};
    default:
      return state;
  }
}
