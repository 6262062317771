import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import * as qs from "querystring";
import React from "react";
import { connect } from "react-redux";
import Alert from "react-s-alert";
import {
  deletePage,
  fetchPages,
  fetchProducts,
  imageUpload,
  publishContent,
  removeAsset
} from "../../actions/editor";
import VerifyModal from "../layout/VerifyModal";
import { withStyles } from "@material-ui/core/styles";
import { myTheme } from "../../uitheme";
import { myPanels } from "./grapes/panels";
import { myBlocks } from "./grapes/blocks";
import { myComponents } from "./grapes/components";
import { myCommands } from "./grapes/commands";

import { myTraits } from "./grapes/traits";
import PublishPage from "./PublishPage";
import initEditor from "./InitEditor";

const styles = theme => myTheme(theme);

let editor;
class Editor extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      assets: [],
      path: "",
      domain: "",
      id: "",
      page: [],
      stack: "",
      editorLoading: true,
      products: null,
      user: {}
    };
  }

  componentDidMount() {
    editor = undefined;
    let id = "";
    let stack = "";
    const parsed = qs.parse(this.props.location.search.slice(1));
    if (parsed.id) id = parsed.id;
    if (parsed.stack) stack = parsed.stack;
    this.props.fetchPages(id.length ? `page/${id}` : "page/none");
    this.props.fetchProducts(stack);
    this.setState({ id, stack });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      const { errors } = this.props;
      if (errors.pages === "No Page") {
        this.props.history.push("/stacks");
      }
      this.setState({
        errors
      });
    }
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.user !== this.state.user) {
        this.setState({ user: this.props.auth.user });
      }
    }
    if (prevProps.editor !== this.props.editor) {
      const { data } = this.props.editor;
      if (data && data.asset) {
        this.addAssetLink(data);
      }
      if (data && data.page) {
        const { domain, path, _id, stack } = data.page;
        this.setState({ page: data.page, domain, path, id: _id, stack }, () => {
          if (this.state.products !== null) this.startLoadingEditor();
        });
      }
      if (data && data.products) {
        this.setState({ products: data.products || [] }, () => {
          if (this.state.domain !== "") this.startLoadingEditor();
        });
      }
    }
  }

  async loadEditor() {
    if (!editor) {
      //moved to it's own folder - InitEditor.js
      editor = await initEditor({
        editor,
        state: this.state,
        imageUpload: this.props.imageUpload,
        removeAsset: this.props.removeAsset
      });
      editor.on("storage:load", () => this.setState({ editorLoading: false }));
      myCommands(editor);
      myPanels(editor, this.props.auth.user);
      myBlocks(editor, this.state);
      myTraits(editor, this.state);
      myComponents(editor, this.state);
    }
  }

  startLoadingEditor() {
    const self = this;
    if (!editor) {
      //load in CKeditor
      let externalScript = document.createElement("script");
      externalScript.setAttribute("src", "./ckeditor/ckeditor.js");
      document.head.appendChild(externalScript);
      let createCKE = window.setInterval(function() {
        if (window.CKEDITOR) {
          clearInterval(createCKE);
          //load in editor
          self.loadEditor();
        }
      }, 100);
    }
  }

  addAssetLink = asset => {
    const am = editor.AssetManager;
    let assets = am.getAll().models;
    const found = assets.find(a => a.id === asset.src);

    if (typeof found === "undefined") am.add(asset);
  };

  render() {
    const { loading } = this.props.editor;
    const { user } = this.props.auth;
    const { classes, errors, publishContent } = this.props;
    const { page, editorLoading } = this.state;
    return (
      <div className="editor_container">
        <div className="spinner" hidden={!loading && !editorLoading}></div>
        <div
          className="editor-row"
          style={{ display: loading || editorLoading ? "none" : "" }}
        >
          <div className="editor-canvas">
            <div className="panel__top">
              <VerifyModal
                buttonLabel="Exit"
                title="Exit Without Saving?"
                confirmLabel="Exit Without Saving"
                cancelLabel="Nevermind"
                changes={() => editor.getModel().get("changesCount")}
                continueAction={() =>
                  this.props.history.push("/stack/" + this.state.stack)
                }
                descText="You have unsaved changes! If you exit now, you'll lose those changes."
                buttonClass={classes.button}
                style={{
                  margin: 0,
                  borderRadius: 0,
                  height: "100%",
                  boxShadow: "none"
                }}
              />
              <Button
                variant="contained"
                className={classes.button}
                style={{
                  margin: 0,
                  borderRadius: 0,
                  height: "100%",
                  boxShadow: "none"
                }}
                onClick={() => {
                  const changes = editor.getModel().get("changesCount");
                  if (changes) {
                    this.setState({ editorLoading: true });
                    editor.store(() => {
                      this.setState({ editorLoading: false });
                      Alert.success("Saved!");
                    });
                  } else Alert.success("Saved!");
                }}
              >
                Save
              </Button>
              <PublishPage
                classes={classes}
                loading={loading}
                pageInfo={page}
                errors={errors}
                user={user}
                editPage={sendValues => {
                  const pageCss = editor.getCss();
                  const pageHtml = editor.getHtml();
                  sendValues.html = pageHtml;
                  sendValues.css = pageCss;
                  sendValues.src = {
                    "gjs-styles": JSON.stringify(editor.getStyle()),
                    "gjs-components": JSON.stringify(editor.getComponents())
                  };
                  editor.getModel().set("changesCount", 0);
                  publishContent(sendValues);
                }}
                deletePage={deletePage}
              />
              <Button
                variant="contained"
                className={classes.button}
                style={{
                  margin: 0,
                  borderRadius: 0,
                  height: "100%",
                  boxShadow: "none"
                }}
                onClick={() => {
                  window.open(`https://${page.domain}/${page.path}`, "_blank");
                }}
              >
                View Page
              </Button>
              <div className="panel__view-actions" />
              <div className="panel__more-actions" />
            </div>
            <div id="gjs" ref="grapesjs"></div>
          </div>
          <div className="panel__right">
            <div className="panel__switcher" />
            <div className="panel__elements">
              <div className="blocks-container"></div>
              <div className="layers-container"></div>
              <div className="settings-container">
                <div className="gjs-sm-properties gjs-trt-traits"></div>
              </div>
              <div className="styles-container"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Editor.propTypes = {
  publishContent: PropTypes.func.isRequired,
  removeAsset: PropTypes.func.isRequired,
  imageUpload: PropTypes.func.isRequired,
  errors: PropTypes.object,
  editor: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  publishContent: state.publishContent,
  removeAsset: state.removeAsset,
  imageUpload: state.imageUpload,
  errors: state.errors,
  editor: state.editor,
  auth: state.auth
});

export default connect(mapStateToProps, {
  imageUpload,
  removeAsset,
  publishContent,
  deletePage,
  fetchPages,
  fetchProducts
})(withStyles(styles)(Editor));
