import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { myTheme } from "../../uitheme";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(myTheme);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerifyModal(props) {
  const {
    buttonLabel,
    title,
    confirmLabel,
    cancelLabel,
    continueAction,
    descText,
    buttonClass,
    style,
    changes
  } = props;

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    if (typeof changes === "undefined") {
      setOpen(true);
    } else {
      const applyChanges = changes();
      if (applyChanges > 0) setOpen(true);
      else continueAction();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={style}
        variant="contained"
        className={buttonClass}
        onClick={handleClickOpen}
      >
        {buttonLabel}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{descText}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleClose}
          >
            {cancelLabel}
          </Button>
          <Button
            variant="contained"
            className={classes.dangerButton}
            onClick={() => {
              continueAction();
              handleClose();
            }}
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
