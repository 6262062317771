import axios from "axios";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_ERRORS,
  LOADING_DATA,
  LOADED_DATA,
  AUTH_LOADED,
  AUTH_LOADING,
  PURCHASE_SUCCESS
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import Alert from "react-s-alert";
import { gaAction } from "../utils/ReactGA";
// Cancel Account
export const cancelSubscription = action => async dispatch => {
  try {
    dispatch({ type: AUTH_LOADING });
    const res = await axios.get(`/api/auth/cancelSubscription/${action}`);
    if (res.data.stripe_cancel_at)
      Alert.success(
        `Your account will be erased on ${new Date(
          res.data.stripe_cancel_at
        ).toDateString()} and you will no longer be charged. Thanks for trying StackPages!`
      );
    else Alert.success(`Welcome back to StackPages! :)`);
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Handle a fetch invoices
export const fetchInvoices = () => async dispatch => {
  try {
    dispatch({ type: AUTH_LOADING });
    const res = await axios.get(`/api/auth/fetchInvoices`);
    dispatch({
      type: AUTH_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Handle a purchase
export const submitPayment = (data, history) => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/auth/submitPayment`, data);
    if (res.data) setAuthToken(res.data.token);
    gaAction({ category: "User", action: "Created a Paid Account" });
    dispatch({
      type: PURCHASE_SUCCESS,
      payload: res.data
    });
    history.push("/welcome");
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Handle an upgrade
export const upgradePlan = (data, history) => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/auth/upgradePlan`, data);
    if (res.data) setAuthToken(res.data.token);
    gaAction({ category: "User", action: "Upgraded Plan" });
    dispatch({
      type: PURCHASE_SUCCESS,
      payload: res.data
    });
    Alert.success("You've been upgraded! :)")
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Change account info
export const updateAccount = data => async dispatch => {
  try {
    dispatch({ type: AUTH_LOADING });
    const res = await axios.post(`/api/auth/account`, data);
    gaAction({ category: "User", action: "Changed Account Info" });
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
    Alert.success("Account Updated!");
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Create a lead
export const createLead = data => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/auth/createLead`, data);
    gaAction({ category: "User", action: "Lead Added" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Get a user's users
export const getIntegration = data => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/users/getIntegration`, data);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Get a user's users
export const addIntegration = data => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/users/addIntegration`, data);
    Alert.success("Integration Added!");
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
    gaAction({ category: "User", action: "Added Integration" });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Get a user's users
export const addStripe = data => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post(`/api/users/addStripe`, data);
    gaAction({ category: "User", action: "Added Integration" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Get a user's users
export const adminFetchAllUsers = () => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get(`/api/users/adminFetchAllUsers`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Get a user's users
export const fetchUsers = () => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get(`/api/users/userUsers`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Verify custom domain
export const verifyDomain = domainName => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.get(`/api/users/domains/${domainName}`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Remove custom domain
export const removeDomain = domainName => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.delete(`/api/users/domains/${domainName}`);
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Add custom domain
export const addDomain = data => async dispatch => {
  try {
    dispatch({ type: LOADING_DATA });
    const res = await axios.post("/api/users/domains", data);
    gaAction({ category: "User", action: "Added Domain" });
    dispatch({
      type: LOADED_DATA,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: SET_ERRORS,
      payload: err.response.data
    });
  }
};

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  dispatch({ type: AUTH_LOADING });
  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

// Register User
export const register = ({ name, email, password },history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ name, email, password });

  try {
    dispatch({ type: AUTH_LOADING });
    const res = await axios.post("/api/users", body, config);
    if (res.data) setAuthToken(res.data.token);
    gaAction({ category: "User", action: "Created a Free Account" });
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
    history.push("/welcome");
  } catch (err) {
    console.log(err)
    if (err.response.data) {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    }

    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    dispatch({ type: AUTH_LOADING });
    const res = await axios.post("/api/auth", body, config);
    if (res.data) setAuthToken(res.data.token);
    gaAction({ category: "User", action: "Logged In" });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    // dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data;

    if (errors) {
      dispatch({
        type: SET_ERRORS,
        payload: errors
      });
    }

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

// Logout / Clear Profile
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
};
