import ReactGA from "react-ga";

export const gaAction = ({ category, action }) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    console.log({ category, action });
  else
    ReactGA.event({
      category,
      action
    });
};
