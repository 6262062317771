const defaultStyles = {
  stripeForm: {
    "box-sizing": "border-box",
    height: "auto",
    padding: "10px",
    border: "1px solid transparent",
    "border-radius": "4px",
    "background-color": "white",
    "box-shadow": "0 1px 3px 0 #e6ebf1",
    "-webkit-transition": "box-shadow 150ms ease",
    transition: " box-shadow 150ms ease"
  },
  defaultButton: {
    "-webkit-font-smoothing": "antialiased",
    "box-sizing": "border-box",
    background: "0 0",
    transition: ".2s",
    display: "inline-block",
    "text-align": "center ",
    "padding-top": " 10px ",
    "padding-bottom": " 10px ",
    "padding-left": "25px ",
    "padding-right": "25px ",
    "border-radius": "3px",
    "font-size": "18px",
    border: "1px solid rgba(0,0,0,0.2)",
    "text-decoration": "none ",
    "box-shadow": "0 0 5px 0 black",
    color: "#FFFFFF",
    "background-color": "#e31a13"
  },
  button: {
    width: "100%",
    "margin-top": "10px",
    "line-height": "1.42857143",
    "-webkit-font-smoothing": "antialiased",
    "box-sizing": "border-box",
    background: "0 0",
    transition: ".2s",
    display: "inline-block",
    "text-align": "center ",
    "padding-top": " 10px ",
    "padding-bottom": " 10px ",
    "padding-left": "25px ",
    "padding-right": "25px ",
    "border-radius": "3px",
    "font-size": "18px",
    border: "1px solid rgba(0,0,0,0.2)",
    "text-decoration": "none ",
    "box-shadow": "0 0 5px 0 black",
    color: "#FFFFFF",
    "background-color": "#e31a13"
  },
  inputField: {
    "margin-top": "10px",
    "-webkit-tap-highlight-color": " transparent",
    "-webkit-font-smoothing": " antialiased",
    "line-height": " inherit",
    "text-align": " center",
    "-webkit-appearance": " none",
    "font-size": " 16px",
    padding: "9px",
    width: "100%",
    "background-color": " #fff",
    border: " 2px solid #e6e6e6",
    "border-radius": " 5px"
  },
  productField: {
    color: "inherit",
    "font-size": "inherit",
    "font-weight": "inherit",
    "font-style": "inherit",
    "font-variant": "inherit",
    "text-align": "inherit",
    padding: "9px",
    width: "100%"
  }
};

export { defaultStyles };
